// custom CSS styles
import "./src/css/index.css"

// Set up Apollo search client for every page
import React from "react"
import fetch from "cross-fetch"
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from "@apollo/client"
import { ApolloLink } from "apollo-link"
import { setContext } from "apollo-link-context"
import { BrowserRouter as Router } from "react-router-dom"
const cache = new InMemoryCache()

// const link = new HttpLink({
//   /* Set the endpoint for your GraphQL server, (same as in gatsby-config.js) */
//   uri: process.env.WPGRAPHQL_URL,
//   /* Use fetch from cross-fetch to provide replacement for server environment */
//   fetch
// })
// const client = new ApolloClient({
//   link,
//   cache,
// })

const client = new ApolloClient({
  cache,
  fetch,
  link: ApolloLink.from([
      setContext((req, prev) => {
        return ({
          headers: {
            ...prev.headers,
            // As long as we are on the same domain with or WP install and Gatsby front end, we can use the x-wp-nonce header to authenticate and fetch previews.
            "X-WP-Nonce": req.variables.nonce ? req.variables.nonce : '',
          },
        })
      }
      ),
      new HttpLink({
          uri:  process.env.WPGRAPHQL_URL,
          credentials: 'include',
      }),
  ])
});


export const wrapRootElement = ({ element }) => (
  <Router>
    <ApolloProvider client={client}>
      {element}
    </ApolloProvider>
  </Router>
)


